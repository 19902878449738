import { render, staticRenderFns } from "./MapTruckCarouselNew.vue?vue&type=template&id=f6f72516&scoped=true"
import script from "./MapTruckCarouselNew.vue?vue&type=script&lang=ts"
export * from "./MapTruckCarouselNew.vue?vue&type=script&lang=ts"
import style0 from "./MapTruckCarouselNew.vue?vue&type=style&index=0&id=f6f72516&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6f72516",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapTruckCardNew: require('/app/components/map/MapTruckCardNew.vue').default})
