
import MAP from '@/store/modules/MapModule';
import globalAws from '@/mixins/globalAws';

export default {
  name: 'TruckSize',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    MAP: () => MAP,
  },
  data() {
    return {
      truckOptions: [
        { label: 'L', size: 'L', image: globalAws + '/img/truck_mockups/new-images/new-l.png' },
        { label: 'XL', size: 'XL', image: globalAws + '/img/truck_mockups/new-images/new-xl.png' },
        {
          label: 'XXL',
          size: 'XXL',
          image: globalAws + '/img/truck_mockups/new-images/new-xxl.png',
        },
      ],
      selectedSizes: [...this.value],
    };
  },
  watch: {
    value(newValue) {
      this.selectedSizes = [...newValue];
    },
  },
  methods: {
    async toggleSelection(size) {
      const index = this.selectedSizes.indexOf(size);
      if (index === -1) {
        this.selectedSizes.push(size);
      } else {
        this.selectedSizes.splice(index, 1);
      }
      this.$emit('input', this.selectedSizes);
      MAP.updateCurrentFilter({ ...MAP.current_filter, sizes: this.selectedSizes });
      this.$emit('fetch-trucks');
    },
  },
};
