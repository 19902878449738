
import Vue from 'vue';
import type { PropType } from 'vue'
import dayjs from 'dayjs';
import { IDateTimeSearch, IPartnerLocation, IPartner } from '~/types/misc_data';

import MAP from '@/store/modules/MapModule';
import CART from '@/store/modules/CartModule';
import UTILS from '@/store/modules/UtilityModule';

import DateTimePicker from '~/components/general/utils/DateTimePicker.vue';

export default Vue.extend({
	name: 'MapHeader',

	components: { DateTimePicker },

	props: {
		currentPartner: {
			type: Object as PropType<IPartner>,
			default: null
		}
	},

	data() {
		return {
			time_slots: ['00:00', '06:00', '12:00', '18:00'],

			selected_location: null as IPartnerLocation | null
		};
	},

	computed: {
		MAP: () => MAP,
		CART: () => CART,
		UTILS: () => UTILS,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		formatSearchDate(): string | null {
			if (!CART.date_time_picked) {
				return null;
			}
			const start = dayjs(this.CART.last_time_search.start).format('DD.MM.YYYY HH:mm');
			const end = dayjs(this.CART.last_time_search.end).format('DD.MM.YYYY HH:mm');
			return start + ' - ' + end;
		},

		dialogMapDatePicker(): boolean {
			return this.$route.query.dialog === 'dialog-map-date-picker';
		},

		partnerLocations(): IPartnerLocation[] | null {
			if (this.currentPartner) {
				return this.currentPartner.locations.slice().sort((a, b) => a.name.localeCompare(b.name));
			}
			return null
		},

		currentMapSettings(): { lat: number; lng: number; zoom: number } | null {
			if (this.selected_location) {
				return {
					lat: this.selected_location.map.lat,
					lng: this.selected_location.map.lng,
					zoom: 15
				};
			}
			return null;
		}
	},

	methods: {
		async handlePickerPayload(payload: IDateTimeSearch): Promise<void> {
			if (!payload.start || !payload.end) {
				return
			}
			// Store the last date/times searched - If a booking exists it will update it with the new dates.
			await CART.STORE_DATE_TIME_PICKED(payload);

			this.$emit('times-picked');
		},

		focusOnMap() {
			this.$emit('focus-on-map');
		},

		panToLocation(): void {
			if (!this.currentMapSettings) {
				return;
			}
			// this.$logger.console({message:'pan to location in mapHeader', data: JSON.stringify(this.currentMapSettings)});
			this.$emit('pan-to-location', JSON.parse(JSON.stringify(this.currentMapSettings)));
		}
	}
});
