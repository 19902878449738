
import Vue from 'vue';
import type { PropType } from 'vue';

import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import { createPlaceholderImage } from '@/_helpers/misc_helper';

import { Truck, ITruckGroupBySize, Model } from '@/types/truck';

import MapTruckCarouselNew from '@/components/map/MapTruckCarouselNew.vue';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'MapChooseSizeNew',

	components: { MapTruckCarouselNew },

	props: {
		trucks: {
			type: Object as PropType<ITruckGroupBySize>,
			default: null
		},
		siteId: {
			type: String,
			default: ''
		},
    resetMultipleSizeComponent: {
      type: Number,
      default: 0
    },
	},

	data() {
		return {
			picked_pool: [] as Truck[],
      awsPath: globalAws,
      variations: [
        {
          variationId: '1004265680',
          slotName: 'oldText', // Component-based variation
        },
        {
          variationId: '1004265681',
          slotName: 'newText', // Slot-based variation
        },
      ],
      truck: null as Truck | null,
      componentUpdate: 0
		};
	},

	computed: {
		UTILS: () => UTILS,
		TRUCKS: () => TRUCKS,
		MISC_DATA: () => MISC_DATA,

    truckMinPrice6(): number {
      if (!(this.truck instanceof Truck)) {
        return 0;
      }

      if (this.truck['min-price'].price_6 > 0) {
        return this.truck['min-price'].price_6;
      }

      //fallback old logic

      const prices = this.MISC_DATA.prices;
      const size = (this.truck?.model as Model).size_group.toLowerCase();

      switch (size) {
        case 'l':
          return prices.truck1_6off;
        case 'xl':
          return prices.truck2_6off;
        case 'xxl':
          return prices.truck3_6off;
      }

      return 0;
    },

    promoOneSlotIsActive(): boolean {
      if (!this.MISC_DATA.features) {
        return false;
      }
      return this.MISC_DATA.features['6_hour_blocks'] === true;
    },

		sizeCards(): any {
			const size_cards = [
				{
					label: 'L',
					color: 'cyan123',
					truck_amount: this.trucks.l ? this.trucks.l.length : 0,
					truck_price: this.MISC_DATA.truck_min_price_per_location?.L?.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price_per_location?.L?.price_6
				},
				{
					label: 'XL',
					color: 'orange123',
					truck_amount: this.trucks.xl ? this.trucks.xl.length : 0,
					truck_price: this.MISC_DATA.truck_min_price_per_location?.XL?.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price_per_location?.XL?.price_6,
				},
				{
					label: 'XXL',
					color: 'pink123',
					truck_amount: this.trucks.xxl ? this.trucks.xxl.length : 0,
					truck_price: this.MISC_DATA.truck_min_price_per_location?.XXL?.price_24,
					truck_price_6hours: this.MISC_DATA.truck_min_price_per_location?.XXL?.price_6
				}
			];
			return size_cards.filter((c: any) => c.truck_amount > 0);
		},

		getDefaultPickedTruck(): any {
			return this.trucks[this.sizeCards[0].label.toLowerCase()];
		},

    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },

    placeholderImage(): string {
      if (process.server) {
        return '';
      }
      return createPlaceholderImage({
        width: 600,
        text: this.$t('truck.pictures.none') as string,
      });
    },

    mainImage(): string {
      const truckList = this.picked_pool.length === 0 ? this.getDefaultPickedTruck : this.picked_pool;

      const singleTruck = truckList[0];

      const size = singleTruck.size_group.toLowerCase();

      switch (size) {
        case 'l':
          return this.awsPath + '/img/truck_mockups/new-images/new-l.png';
        case 'xl':
          return this.awsPath + '/img/truck_mockups/new-images/new-xl.png';
        case 'xxl':
          return this.awsPath + '/img/truck_mockups/new-images/new-xxl.png';
      }

      return '';
    },
	},

	methods: {
		updateTruckSize(val: string) {
			this.picked_pool = this.trucks[val.toLocaleLowerCase()];
		},

    currentTruckData(val: Truck) {
      this.truck = val;
    },

    preloadImages() {
      const imagePaths = [
        this.awsPath + '/img/truck_mockups/new-images/new-l.png',
        this.awsPath + '/img/truck_mockups/new-images/new-xl.png',
        this.awsPath + '/img/truck_mockups/new-images/new-xxl.png',
      ];

      imagePaths.forEach((path) => {
        const img = new Image();
        img.src = path;
      });
    }
	},

	watch: {
		siteId: {
			async handler(newVal: string, oldVal: string,) {
				if (newVal !== oldVal) {
					await this.MISC_DATA.GET_TRUCK_MIN_PRICES_PER_LOCATION({ domain: this.$getDomain(), site: newVal });
				}
			}
		},

    resetMultipleSizeComponent: {
      async handler(newVal: number, oldVal: number) {
        if (newVal !== oldVal) {
          this.picked_pool = this.getDefaultPickedTruck;
          this.componentUpdate+=1;
        }
      }
    }
	},

  mounted() {
    this.preloadImages();
  }
});
