import { render, staticRenderFns } from "./MapChooseSizeNew.vue?vue&type=template&id=9db5b0de&scoped=true"
import script from "./MapChooseSizeNew.vue?vue&type=script&lang=ts"
export * from "./MapChooseSizeNew.vue?vue&type=script&lang=ts"
import style0 from "./MapChooseSizeNew.vue?vue&type=style&index=0&id=9db5b0de&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9db5b0de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AbtestWrapper: require('/app/components/abtest/wrapper.vue').default,MapSelectTruckSize: require('/app/components/map/SelectTruckSize.vue').default,MapTruckCarouselNew: require('/app/components/map/MapTruckCarouselNew.vue').default})
