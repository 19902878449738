
import Vue from 'vue';
import dayjs from 'dayjs';
import type { PropType } from 'vue';
import { routesNames } from '@/router';
import CART from '@/store/modules/CartModule';
import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';
import MapTruckCardNew from '@/components/map/MapTruckCardNew.vue';
import { createPlaceholderImage } from '@/_helpers/misc_helper';
import Booking from '~/types/booking/booking';
import { Truck, ITruckGroupBySize, ITruckSlotsFetchParams } from '@/types/truck';
import { ITruckClickPayLoad } from '~/plugins/tracking';
import { ErrorResponse } from '~/types/api_helper';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
  name: 'MapTruckCarouselNew',

  components: { MapTruckCardNew },

  props: {
    truckList: {
      type: Array as PropType<Truck[]>,
      required: true,
    },
    trucks: {
      type: Object as PropType<ITruckGroupBySize>,
      default: null
    },
    isChooseSize: {
      type: Boolean,
      default: false
    },
    resetOneSizeComponent: {
      type: Number,
      default: 0
    },
    resetMultipleSizeComponent: {
      type: Number,
      default: 0
    },
    onLandingPage: Boolean,
  },

  data() {
    return {
      carousel_idx: 0,
      loader: false,
	    trigerData: {
        triggerClick: 0,
        triggerId: '',
      },
      start_date: dayjs().format('YYYY-MM-DD').concat('T00:00'),
			end_date: dayjs().add(8, 'weeks').format('YYYY-MM-DD').concat('T00:00'),
      allTrucksInDialog: [] as (Truck | ErrorResponse)[],
      allSlotsInDialog: [] as { truck_id: string, slots: number[] | ErrorResponse }[],
      awsPath: globalAws,
      resetCalendarPage: 0,
      sizes: {
        l: 1,
        xl: 1,
        xxl: 1,
      },
    };
  },

  computed: {
    CART: () => CART,
    TRUCKS: () => TRUCKS,
    UTILS: () => UTILS,

    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },

    truckOrderedByAvailability(): Truck[] {
      return [...this.truckList].sort((a: Truck, b: Truck) => b.order - a.order);
    },

    // truckLOrderedByAvailability(): Truck[] {
    //   return [...this.truckList].sort((a: Truck, b: Truck) => b.order - a.order);
    // },

    placeholderImage(): string {
      if (process.server) {
        return '';
      }
      return createPlaceholderImage({
        width: 600,
        text: this.$t('truck.pictures.none') as string,
      });
    },

    truckCounter(): string {
      return this.truckList.length > 1 ? `${this.carousel_idx + 1}/${this.truckList.length}` : '';
    },

    truckIds(): any {
      return this.truckOrderedByAvailability?.map(item => item.id);
    },

    disableLeftButton(): boolean {
      const getSize = this.truckList[0].size_group.toLowerCase() as keyof typeof this.sizes;
      if (this.isChooseSize) {
        return this.carousel_idx === 0 && this.sizes[getSize] + 1 !== this.truckList.length;
      }
      return this.carousel_idx === 0 && this.allTrucksInDialog.length !== this.truckList.length;
    },

    generateTruckSlotsParams(): ITruckSlotsFetchParams[] {
      return this.truckOrderedByAvailability?.map(item => ({
          truck_id: item.id,
          truck_location: item.position.site_id,
          start: this.start_date,
          end: this.end_date
      })) || [];
    },

    truckOrderedByAvailabilitySizes(): { l?: Truck[]; xl?: Truck[]; xxl?: Truck[] } {
      const sortedTrucks: { l?: Truck[]; xl?: Truck[]; xxl?: Truck[] } = {};

      if (this.trucks.l) {
        sortedTrucks.l = [...this.trucks.l]
          .sort((a: Truck, b: Truck) => b.order - a.order)
          .slice(0, 2);
      }
      if (this.trucks.xl) {
        sortedTrucks.xl = [...this.trucks.xl]
          .sort((a: Truck, b: Truck) => b.order - a.order)
          .slice(0, 2);
      }
      if (this.trucks.xxl) {
        sortedTrucks.xxl = [...this.trucks.xxl]
          .sort((a: Truck, b: Truck) => b.order - a.order)
          .slice(0, 2);
      }

      return sortedTrucks;
    },

    generateTruckSlotsParamsForSizes(): any {      
      return Object.values(this.truckOrderedByAvailabilitySizes)
      .filter(group => group)
      .flatMap(group =>
        group.map(item => ({
          truck_id: item.id,
          truck_location: item.position.site_id,
          start: this.start_date,
          end: this.end_date
        }))
      );
    },

    truckIdsCombined(): string[] {
      const sortedTrucks = this.truckOrderedByAvailabilitySizes;

      return Object.values(sortedTrucks as { [key: string]: Truck[] | undefined })
        .filter((group): group is Truck[] => Array.isArray(group))
        .flatMap(group => group.map(truck => truck.id));
    },

    mainImage(): string {
      const singleTruck = this.truckOrderedByAvailability[0];

      const size = singleTruck.size_group.toLowerCase();

      switch (size) {
        case 'l':
          return this.awsPath + '/img/truck_mockups/new-images/new-l.png';
        case 'xl':
          return this.awsPath + '/img/truck_mockups/new-images/new-xl.png';
        case 'xxl':
          return this.awsPath + '/img/truck_mockups/new-images/new-xxl.png';
      }

      return '';
    }
  },

  async mounted() {
    this.sizes.l = 1;
    this.sizes.xl = 1;
    this.sizes.xxl = 1;
    this.carousel_idx = 0;
    // Run the truck tracking here for the first one in the list
    this.trigerClickEvent();
    if (this.isChooseSize) {
      await this.initializeSizes()
    } else {
      await this.initialize();
    }
  },

  watch: {
    truckList() {
      this.carousel_idx = 0; // Reset idx when user clicks outside of the dialog to close it

	  // also reset again after 1 tick to "fix" the issue when navigating back
	  this.$nextTick().then(()=>{
		this.carousel_idx = 0;
	  })
    },

    resetOneSizeComponent: {
      async handler(newVal: number, oldVal: number) {
        if(newVal !== oldVal) {
          this.carousel_idx = 0;
          this.resetCalendarPage +=1;
          await this.initialize();
        }
      }
    },

    resetMultipleSizeComponent: {
      async handler(newVal: number, oldVal: number) {
        if (newVal !== oldVal) {
          this.carousel_idx = 0;
          this.resetCalendarPage +=1;
          this.sizes.l = 1;
          this.sizes.xl = 1;
          this.sizes.xxl = 1;
          await this.initializeSizes();
        }
      }
    },
  },

  methods: {
    async toUpsells(truck: string): Promise<void> {
      const booking = new Booking(this.CART.booking);
      booking.truck = truck;
      await this.CART.STORE_BOOKING_DATA(booking);
      this.$router.push({ name: routesNames.upsells_1 });
    },

    currentTruckData(val: Truck) {
		  this.$emit('currentTruck', val);
    },

    trigerClickEvent() {
      const currentTruck = this.truckOrderedByAvailability[this.carousel_idx];
      this.trigerData.triggerId = currentTruck.id;
      this.trigerData.triggerClick += 1;
    },

    trigerTruckClickEvent(val: any) {
      const currentTruck = this.truckOrderedByAvailability[this.carousel_idx];

      if (!currentTruck || !currentTruck.id || this.onLandingPage) {
        return;
      }

      const [page, page_num] = this.truckCounter ? this.truckCounter.split('/') : [1, 1];

      const payload = {
        truck_id: currentTruck.id,
        site_id: currentTruck.position?.site_id,
        lat: currentTruck.position?.lat,
        lng: currentTruck.position?.lng,
        page: +page,
        num_pages: +page_num,
        blocked_slots: val.blocked_slots,
        available_slots: val.available_slots,
      } as ITruckClickPayLoad;

      this.$tracking.truck_click(payload);
    },

    previous(): void {
      this.carousel_idx = this.carousel_idx - 1 < 0 ? this.truckList.length - 1 : this.carousel_idx - 1;
      this.$nextTick(() => {
        this.trigerClickEvent();
      });
    },

    next(): void {
      this.carousel_idx = this.carousel_idx + 1 === this.truckList.length ? 0 : this.carousel_idx + 1;
      this.$nextTick(() => {
        this.trigerClickEvent();
        if (!this.isChooseSize) {
          if (this.allTrucksInDialog.length - 1 === this.carousel_idx) {
            this.preloadNextTruck();
            this.preloadNextSlot();
          }
        } else {
          if (this.carousel_idx !== this.truckList.length - 1) {
            this.preloadNextTruck();
            this.preloadNextSlot();
          }
        }
      });
    },

    async initialize() {
      let initialTruckIds: string[] = [];
      let initialSlotIds: ITruckSlotsFetchParams[] = [];

      const truckSlotParams = this.generateTruckSlotsParams;

      const currentTruckId = this.truckIds[0];
      const currentSlotId = truckSlotParams[0];

      if (this.truckIds.length > 1) {
        const nextTruckId = this.truckIds[1];
        const nextSlotId = truckSlotParams[1];
        initialTruckIds = [currentTruckId, nextTruckId];
        initialSlotIds = [currentSlotId, nextSlotId];
      } else {
        initialTruckIds = [currentTruckId];
        initialSlotIds = [currentSlotId]
      }

      const results = await this.TRUCKS.GET_ALL_TRUCKS_DATA({ truck_ids: initialTruckIds });

      const slots = await this.TRUCKS.FETCH_TRUCK_SLOTS(initialSlotIds);

      this.allSlotsInDialog = slots;

      this.allTrucksInDialog = results;
    },

    async initializeSizes() {
      const truckSlotParams = this.generateTruckSlotsParamsForSizes;
      const truckIds = this.truckIdsCombined;

      const results = await this.TRUCKS.GET_ALL_TRUCKS_DATA({ truck_ids: truckIds });

      const slots = await this.TRUCKS.FETCH_TRUCK_SLOTS(truckSlotParams);

      this.allSlotsInDialog = slots;

      this.allTrucksInDialog = results;
    },

    async preloadNextTruck() {
      if (!this.isChooseSize) {
        if (this.carousel_idx >= 1 && this.allTrucksInDialog.length !== this.truckIds.length) {
          const getTruck = [this.truckIds[this.carousel_idx + 1]];
          const results = await this.TRUCKS.GET_ALL_TRUCKS_DATA({ truck_ids: getTruck });
          if (results) {
            this.allTrucksInDialog.push(results[0]);
          }
        }
      } else {
        if (this.carousel_idx >= 1) {
          const getTruck = [this.truckIds[this.carousel_idx + 1]];
          const exist = this.allTrucksInDialog.find(item => 'id' in item && typeof item.id === 'string' && item.id === getTruck[0]) || null;
          if (!exist) {
            const sizes = {l: 1, xl: 1, xxl: 1};
            const getSize = this.truckList[0].size_group.toLowerCase() as keyof typeof sizes;
            this.sizes[getSize] = this.sizes[getSize] + 1;
            const results = await this.TRUCKS.GET_ALL_TRUCKS_DATA({ truck_ids: getTruck });
            if (results) {
              this.allTrucksInDialog.push(results[0]);
            }
          }
        }
      }
    },

    async preloadNextSlot() {
      if (!this.isChooseSize) {
        if (this.carousel_idx >= 1 && this.allTrucksInDialog.length !== this.truckIds.length) {
          const getTruckSlot = [this.generateTruckSlotsParams[this.carousel_idx + 1]];
          const slots = await this.TRUCKS.FETCH_TRUCK_SLOTS(getTruckSlot);
          if (slots) {
            this.allSlotsInDialog.push(slots[0]);
          }
        }
      } else {
        if (this.carousel_idx >= 1) {
          const getTruckSlot = [this.generateTruckSlotsParams[this.carousel_idx + 1]];
          const truckId = getTruckSlot[0].truck_id;
          const exist = this.allSlotsInDialog.find(truck => truck.truck_id === truckId);
          if (!exist) {
            const slots = await this.TRUCKS.FETCH_TRUCK_SLOTS(getTruckSlot);
            if (slots) {
              this.allSlotsInDialog.push(slots[0]);
            }
          }
        }
      }
    }
  },
});
