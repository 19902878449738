import { render, staticRenderFns } from "./MapWrapper.vue?vue&type=template&id=fcda4828"
import script from "./MapWrapper.vue?vue&type=script&lang=ts"
export * from "./MapWrapper.vue?vue&type=script&lang=ts"
import style0 from "./MapWrapper.vue?vue&type=style&index=0&id=fcda4828&prod&scope=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapHeader: require('/app/components/map/MapHeader.vue').default,MapTruckSize: require('/app/components/map/TruckSize.vue').default,GeneralUtilsSelectDropdown: require('/app/components/general/utils/SelectDropdown.vue').default,MapTruckPrice: require('/app/components/map/TruckPrice.vue').default,MapTruckCardNew: require('/app/components/map/MapTruckCardNew.vue').default,MapChooseSizeNew: require('/app/components/map/MapChooseSizeNew.vue').default,MapTruckCarouselNew: require('/app/components/map/MapTruckCarouselNew.vue').default})
