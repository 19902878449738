
import MAP from '@/store/modules/MapModule';
import currentDomain from '@/mixins/currentDomain';

export default {
  name: 'TruckPrice',
  props: {
    value: {
      type: [Number, String, Array],
      default: 100,
    },
  },
  mixins: [currentDomain],
  data() {
    return {
      localPrice: '',
      priceRange: '',
      currency: '',
    };
  },
  methods: {
    getDomainInfo() {
      if (this.isDeDomain || this.isAtDomain) {
        return {
          priceRange: { min: 9, max: 44, step: 5 },
          currency: '€',
        };
      }
      if (this.isCzDomain) {
        return {
          priceRange: { min: 390, max: 1090, step: 100 },
          currency: 'CZK',
        };
      }
      if (this.isHuDomain) {
        return {
          priceRange: { min: 8990, max: 12990, step: 1000 },
          currency: 'HUF',
        };
      }
      return {
        priceRange: { min: 9, max: 44, step: 5 },
        currency: '€',
      };
    },
    async updatePrice() {
      this.$emit('input', this.localPrice);
      MAP.updateCurrentFilter({ ...MAP.current_filter, max_price: this.localPrice.toString() });
      this.$emit('fetch-trucks');
    },
  },
  mounted() {
	const domainInfo = this.getDomainInfo();
	this.localPrice = domainInfo.priceRange.max;
    this.priceRange = domainInfo.priceRange;
    this.currency = domainInfo.currency;
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.localPrice = Array.isArray(newValue) ? newValue[0] : Number(newValue);
        } else {
          this.localPrice = this.priceRange.max; // Fallback to max value
        }
      },
      immediate: true,
    },
  },
};
