
import Vue from 'vue';
import type { PropType } from 'vue';
import MISC_DATA from '@/store/modules/MiscDataModule';
import { ITruckGroupBySize, Truck  } from '@/types/truck';
import globalAws from '@/mixins/globalAws';

export default Vue.extend({
	name: 'SelectTruckSize',

	props: {
		trucks: {
			type: Object as PropType<ITruckGroupBySize>,
			default: null
		},
		truckList: {
			type: Array as PropType<Truck[]>,
			default: null
		},
	},

	data() {
		return {
			selectedTruckSize: '',
			awsPath: globalAws,
		};
	},

	computed: {
		MISC_DATA: () => MISC_DATA,

		sizeCards(): any {
			if (this.trucks) {
				const size_cards = [
					{
						label: 'L',
						color: 'cyan123',
						truck_amount: this.trucks.l ? this.trucks.l.length : 0,
						truck_price: this.MISC_DATA.truck_min_price.L.price_24,
						truck_price_6hours: this.MISC_DATA.truck_min_price.L.price_6
					},
					{
						label: 'XL',
						color: 'orange123',
						truck_amount: this.trucks.xl ? this.trucks.xl.length : 0,
						truck_price: this.MISC_DATA.truck_min_price.XL.price_24,
						truck_price_6hours: this.MISC_DATA.truck_min_price.XL.price_6,
					},
					{
						label: 'XXL',
						color: 'pink123',
						truck_amount: this.trucks.xxl ? this.trucks.xxl.length : 0,
						truck_price: this.MISC_DATA.truck_min_price.XXL.price_24,
						truck_price_6hours: this.MISC_DATA.truck_min_price.XXL.price_6
					}
				];
				return size_cards.filter((c: any) => c.truck_amount > 0);
			}
		},
	},

	mounted() {
		if (this.truckList) {
			if (this.truckList[0]) {
				this.selectedTruckSize = this.truckList[0].size_group.toLowerCase();
			}
		}
	},

	methods: {
		selectSize(val: string) {
			this.selectedTruckSize = val.toLowerCase();
			this.$emit('updateTruckSize', val);
		}
	}
});
