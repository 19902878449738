
import Vue from 'vue';
import type { PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';
import { priceWithCommas } from '@/_helpers/misc_helper'

import MAP from '@/store/modules/MapModule';
import TRUCKS from '@/store/modules/TruckModule';
import UTILS from '@/store/modules/UtilityModule';

import { Model, Truck } from '~/types/truck';

interface InfoTableRow {
	label: TranslateResult;
	value: string | number | TranslateResult | undefined;
}

export default Vue.extend({
	name: 'TruckInfoNew',

	props: {
		truck: {
			type: Object as PropType<Truck>,
			required: true
		},
		flat: Boolean,
		closeButton: Boolean
	},

	data() {
		return {
			shown_img: 0,
			expand_dimensions: false,

			map: {
				apiKey: this.$config.googleApiKey,
				zoom: 15, // required
				format: 'png',
				language: 'de',
				type: 'roadmap',
				size: {
					w: 900,
					h: 250
				}
			},

			formatThousands: priceWithCommas
		};
	},

	computed: {
		MAP: () => MAP,
		TRUCKS: () => TRUCKS,
		UTILS: () => UTILS,

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		truckDetails(): InfoTableRow[] {
			const details = this.truck.model as Model
			return [
				{ label: this.$t('truck.size_group'), value: details.size_group },
				{ label: this.$t('truck.model'), value: details.model },
				{ label: this.$t('truck.year_construction'), value: this.truck.year_of_construction },
				{ label: this.$t('truck.color'), value: this.truck.color },
				{ label: this.$t('truck.seats'), value: details.seats },
				{ label: this.$t('truck.shift_type'), value: details.shift_type === 'MANUAL' ? this.$t('truck.manual') : this.$t('truck.automatic') },
				{ label: this.$t('truck.fuel.type'), value: details.fuel.type === 'DIESEL' ? this.$t('truck.diesel') : this.$t('truck.benzyme') },
				{ label: this.$t('truck.weight'), value: this.$t('general.in_kg_short', { value: this.formatThousands(details.weight.self) }) },
				{ label: this.$t('truck.load.max_payload'), value: this.$t('general.in_kg_short', { value: this.formatThousands(details.weight.additional) }) },
				{ label: this.$t('truck.fastening_eyes'), value: details.fastening_eyes }
			];
		},

		truckDimensions(): InfoTableRow[] {
			const outer = (this.truck.model as Model).measurements.outside;
			const inner = (this.truck.model as Model).measurements.inside;
			return [
				{ label: this.$t('truck.outer_dimensions'), value: `${outer.length}(${this.$t('truck.dimension.length.short')}) / ${outer.width}(${this.$t('truck.dimension.width.short')}) / ${outer.height}(${this.$t('truck.dimension.height.short')})` },
				{ label: this.$t('truck.inner_dimensions'), value: `${inner.length}(${this.$t('truck.dimension.length.short')}) / ${inner.width}(${this.$t('truck.dimension.width.short')}) / ${inner.height}(${this.$t('truck.dimension.height.short')})` }
			];
		},

		truckExtraDimensions(): InfoTableRow[] {
			const dimensions = (this.truck.model as Model).measurements as any
			const list = [
				{ label: this.$t('truck.narrowest_point'), value: dimensions.narrowest_point },
				{ label: this.$t('truck.loading_height'), value: dimensions.loading_height },
				{ label: this.$t('truck.back_opening_width'), value: dimensions.back_opening.width },
				{ label: this.$t('truck.back_opening_height'), value: dimensions.back_opening.height },
				{ label: this.$t('truck.driver_opening_height'), value: dimensions.driver_side_opening.height },
				{ label: this.$t('truck.driver_opening_width'), value: dimensions.driver_side_opening.width },
				{ label: this.$t('truck.side_opening_height'), value: dimensions.codriver_side_opening.height },
				{ label: this.$t('truck.side_opening_width'), value: dimensions.codriver_side_opening.width }
			]
			return list.filter((dim) => dim.value)
		},

		showExtras(): boolean {
			const extras = (this.truck.model as Model).extras;
			const list = {
				ac: extras.air_conditioning,
				hook: extras.hook.exists,
				lift: extras.lift.exists,
				rack: extras.rack.exists,
			}
			// Check if any value is set in extras
			return Object.values(list).some((value) => value);
		}
	},

	methods: {
		expandDimensionsPanel(): void {
			this.expand_dimensions = !this.expand_dimensions;
		}
	}
});
